import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";

import Landingpage from "./screens/Ladingpage";
import Legal from "./screens/Legal";

const developerMode = true;

export default function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/imprint" element={<Legal />} />
        </Routes>
        <></>
      </Router>
    </div>
  );
}
