import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdBusiness,
  MdBusinessCenter,
  MdCampaign,
  MdChildCare,
  MdFavoriteBorder,
  MdFitnessCenter,
  MdLanguage,
  MdLightMode,
  MdMenu,
  MdOutlineCampaign,
  MdOutlineShoppingCart,
  MdPersonOutline,
  MdPets,
  MdRestaurant,
  MdSearch,
  MdShield,
  MdShoppingCart,
  MdSports,
  MdSportsMma,
} from "react-icons/md";
import "../../styles/Topnavbar.css";
import { useLocation, useNavigate } from "react-router";
import {
  FaBlog,
  FaDollarSign,
  FaGamepad,
  FaLanguage,
  FaTshirt,
} from "react-icons/fa";
import { FaPerson, FaSnapchat } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";

import { useLanguage } from "../../contexts/LanguageContext";
import texts from "../../texts/Texts";
import Bottomnavbar from "./Bottomnavbar";
import { isEmpty } from "../../functions/isEmpty";

export default function HomeTopNav({
  isMobile,
  filterBranch,
  setFilterBranch,
  authToken,
  setFilterProductStart,
  setFilterProductEnd,
  filterProductOrService,
  setFilterProductOrService,
  setOpenFilter,
  cart,
}) {
  let navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const location = useLocation();

  const handleLanguageChange = (newLanguage) => {
    switchLanguage(newLanguage);
  };

  useEffect(() => {}, [location]);

  const handleClickBranch = (title, start, end) => {
    setFilterBranch(title);
    setFilterProductStart(start);
    setFilterProductEnd(end);
  };

  function capitalizeFirstLetter(inputString) {
    // Überprüfen, ob der String nicht leer ist
    if (inputString.length === 0) {
      return inputString; // Rückgabe des leeren Strings
    }

    // Extrahieren des ersten Buchstabens und Umwandeln in Großbuchstaben
    const firstLetter = inputString.charAt(0).toUpperCase();

    // Verbinden des ersten Buchstabens mit dem Rest des Strings
    const resultString = firstLetter + inputString.slice(1);

    return resultString;
  }

  return (
    <>
      {isMobile ? (
        <div className="topnavbar">
          <div
            className="topnavbar_left"
            onClick={() => setOpenFilter("branch")}>
            <div className="topnavbar_left_left">
              <div className="topnavbar_left_left_toptext">creator-ads for</div>
              <div className="topnavbar_left_left_bottomtext">
                {filterBranch}
              </div>
            </div>
            <div className="topnavbar_left_right">
              <ArrowDropdown />
            </div>
          </div>
          <div className="topnavbar_right">
            <div className="topnavbar_right_iconbox">
              <PersonIcon />
            </div>
            <div
              className="topnavbar_right_iconbox"
              onClick={() => navigate("/cart")}>
              <ShoppingCartIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className="topnavbar">
          <div className="topnavbar_top">
            <div className="topnavbar_top_left">
              <div
                className="topnavbar_top_left_logo"
                onClick={() => navigate("/")}>
                {/*
                <img
                src="../../../logo-desktop.png"
                className="topnavbar_top_left_logo"
              />
              */}
                creator-ads
              </div>
            </div>
            <div className="topnavbar_top_navbar">
              {/*
                  {language == "en" ? (
                <div
                  className="topnavbar_top_left_language"
                  onClick={() => handleLanguageChange("de")}
                >
                  EN
                </div>
              ) : (
                <div
                  className="topnavbar_top_left_language"
                  onClick={() => handleLanguageChange("en")}
                >
                  DE
                </div>
              )}
              */}
              <div
                className="topnavbar_top_navbar_navElement"
                onClick={() => navigate("/cart")}>
                {cart.length + " "} {texts[language].orderIcon}
              </div>
              <div
                className="topnavbar_top_navbar_navElement"
                onClick={() => navigate("/profile")}>
                {texts[language].profileIcon}
              </div>
            </div>
          </div>
          <div className="topnavbar_bottom">
            <div className="topnavbar_bottom_scroll">
              {filterBranch == "fashion" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <FashionIconActive />
                  {texts[language].fashionIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("fashion", 1, 500)}>
                  <FashionIcon />
                  {texts[language].fashionIcon}
                </div>
              )}

              {filterBranch == "beauty" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <BeautyIconActive />
                  {texts[language].beautyIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("beauty", 1001, 1500)}>
                  <BeautyIcon />
                  {texts[language].beautyIcon}
                </div>
              )}
              {filterBranch == "gaming" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <GamingIconActive />
                  {texts[language].gamingIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("gaming", 2001, 2500)}>
                  <GamingIcon />
                  {texts[language].gamingIcon}
                </div>
              )}

              {filterBranch == "finance" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <BusinessIconActive />
                  {texts[language].businessIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("finance", 3001, 3500)}>
                  <BusinessIcon />
                  {texts[language].businessIcon}
                </div>
              )}

              {filterBranch == "sport" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <SportIconActive />
                  {texts[language].sportsIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("sport", 4001, 4500)}>
                  <SportIcon />
                  {texts[language].sportsIcon}
                </div>
              )}

              {filterBranch == "food" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <FoodIconActive />
                  {texts[language].foodIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("food", 5001, 5500)}>
                  <FoodIcon />
                  {texts[language].foodIcon}
                </div>
              )}
              {filterBranch == "assets" ? (
                <div className="topnavbar_bottom_scroll_iconBox_active">
                  <AssetIconActive />
                  {texts[language].assetIcon}
                </div>
              ) : (
                <div
                  className="topnavbar_bottom_scroll_iconBox"
                  onClick={() => handleClickBranch("assets", 6001, 6500)}>
                  <AssetIcon />
                  {texts[language].assetIcon}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!isEmpty(authToken) && <Bottomnavbar isMobile={isMobile} />}
    </>
  );
}

const ArrowBack = styled(MdArrowBack)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-right: 5px;
`;

const ArrowDropdown = styled(MdArrowBackIos)`
  font-size: 18px;
  color: #222222;
  transform: rotate(-90deg);
  margin-left: 2px;
`;

const MenuIcon = styled(MdMenu)`
  font-size: 29px;
  color: #ffffff;
`;

/* */

const SearchIcon = styled(MdSearch)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  color: #8f8f8f;
`;

const OrderIcon = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #8f8f8f;
`;

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #3b3b3b;
`;

const ShoppingCartIcon = styled(MdOutlineShoppingCart)`
  font-size: 26px;
  color: #3b3b3b;
`;

const FashionIcon = styled(FaTshirt)`
  font-size: 26px;
  color: #8f8f8f;
`;

const LanguageIcon = styled(MdLanguage)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BeautyIcon = styled(BsStars)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BusinessIcon = styled(FaDollarSign)`
  font-size: 26px;
  color: #8f8f8f;
`;

const GamingIcon = styled(FaGamepad)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FoodIcon = styled(MdRestaurant)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FamilyIcon = styled(MdChildCare)`
  font-size: 26px;
  color: #8f8f8f;
`;

const SportIcon = styled(MdFitnessCenter)`
  font-size: 26px;
  color: #8f8f8f;
`;

const AssetIcon = styled(MdBusiness)`
  font-size: 26px;
  color: #8f8f8f;
`;

/* ACTIVE */

const FashionIconActive = styled(FaTshirt)`
  font-size: 26px;
  color: #f58700;
`;

const BeautyIconActive = styled(BsStars)`
  font-size: 26px;
  color: #f58700;
`;

const BusinessIconActive = styled(FaDollarSign)`
  font-size: 26px;
  color: #f58700;
`;

const GamingIconActive = styled(FaGamepad)`
  font-size: 26px;
  color: #f58700;
`;

const FoodIconActive = styled(MdRestaurant)`
  font-size: 26px;
  color: #f58700;
`;

const FamilyIconActive = styled(MdChildCare)`
  font-size: 26px;
  color: #f58700;
`;

const SportIconActive = styled(MdFitnessCenter)`
  font-size: 26px;
  color: #f58700;
`;

const AssetIconActive = styled(MdBusiness)`
  font-size: 26px;
  color: #f58700;
`;
