import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowForward,
  MdAttachFile,
  MdCancel,
  MdMenu,
  MdMoreHoriz,
  MdOutlineImage,
} from "react-icons/md";

import { useNavigate, useParams } from "react-router";
import Card from "./cards/Card";

export default function CatalogComponent({ isMobile, data }) {
  const [storyDescription, setStoryDescription] = useState("");

  let navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="catalogelement">
      <div className="catalogelement_left">
        <img
          src="https://framerusercontent.com/images/tQ8VmwCLSTZ23VzFIJ5Ej1hjE.jpg?scale-down-to=1024"
          className="catalogelement_left_img"
        />
      </div>
      <div className="catalogelement_right">
        <div className="catalogelement_right_title">{data.title}</div>
        {data.services.map((service) => (
          <div className="catalogelement_right_element">
            <div className="catalogelement_right_element_title">
              {service.title}
            </div>
            <div className="catalogelement_right_element_button">+</div>
          </div>
        ))}
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;
